<template>
  <BaseImport>
    <template slot="button">
      <el-button size="small" type="danger" @click="handleCancel">Cancel Import</el-button>
    </template>
    <template slot="content">
      <el-row type="flex">
        <el-col class="body-content-container">
          <el-row type="flex" style="padding: 30px; margin: 5rem 0rem 5rem 0rem; height: 100%">
            <el-col :xs="24" class="body-content-container">
              <div class="body-content-container__title">
                <div class="body-content-container__title--text">
                  <span>Do you have a product spreadsheet?</span>
                </div>
              </div>
              <div class="body-content-container-content">
                <div class="body-content-container-content__option" @click="handleUpload">
                  <div class="body-content-container-content__option--text">
                    <span style="font-weight: 400">I have a spreadsheet with product data ready to uploads</span>
                  </div>
                  <div class="body-content-container-content__option--text">
                    <span style="color: #71748d">Choose this option if you have exported data from another system, or want to upload a corrected spreadsheet from a previous import.</span>
                  </div>
                </div>
                <div class="body-content-container-content__option" @click="handleGenerate">
                  <div class="body-content-container-content__option--text">
                    <span style="font-weight: 400">I need a product spreadsheet template.</span>
                  </div>
                  <div class="body-content-container-content__option--text">
                    <span style="color: #71748d">Choose this option if you are just starting out or want to follow Gefyra template</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
  </BaseImport>
</template>

<style lang="scss" scoped>
.body-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;

  &__title {
    width: 100%;
    &--text {
      margin-bottom: 15px;
      span {
        font-size: 1.25rem;
        text-overflow: clip;
        font-weight: 500;
      }
    }
  }

  &-content {
    &__option {
      border-top: 1px solid dodgerblue;
      border-bottom: 1px solid dodgerblue;
      padding: 30px 0px;
      &--text {
        cursor: pointer;
        width: 100%;
        text-overflow: clip;
        overflow-wrap: break-word;

        span {
          color: #66B1FF;
          line-height: 1.6rem;
          font-size: 1rem;
        }
      }
    }
    &__option:nth-of-type(2) {
      border-top: 0px;
    }
  }
}
</style>

<script>
export default {
  data () {
    return {
      isUpload: false,
      isGenerate: false,
      isCancel: false
    }
  },
  methods: {
    handleGenerate () {
      this.$router.push({ name: 'inventory-generate' })
    },
    handleUpload () {
      this.$router.push({ name: 'inventory-upload' })
    },
    handleCancel () {
      this.$router.push({ name: 'product-list' })
    }
  }
}
</script>
